import React from "react"
import StorageProvider from './_storageProvider';
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import Content_FeatureTrust from "components/content/feature_trust-backups.js";
import Content_FeatureControl from "components/content/feature_storage-control.js";

const ContentPage = () => (
  <StorageProvider keyRef="dropbox" provider="Dropbox"></StorageProvider>
)

export default ContentPage
